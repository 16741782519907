import { EcoAlert, EcoAvatar, EcoButton, EcoCard, EcoIcon, EcoImage, EcoLink, EcoStatusBeacon, EcoText } from '@components/shared';
import { EcoTooltip } from '@components/shared/EcoTooltip';
import { useFeaturePermission } from '@hooks/useFeaturePermission';
import { useMerchantStatusConfigs } from '@hooks/useMerchantStatusConfigs';
import { useUpdateMerchantAdminOnboarding } from '@hooks/useUpdateMerchantAdminOnboarding';
import { useNavigation } from '@react-navigation/native';
import { getMerchantAdmin } from '@utils/api/merchant';
import { getPaymentMethods, hasAtLeastOnePaymentMethod, hasAtLeastOneValidPaymentMethod } from '@utils/api/payments';
import { DEFAULT_AVATAR, PUBLIC_IMG_URL } from '@utils/constants/config';
import { SIDE_NAV_WIDTH, TOP_NAV_HEIGHT } from '@utils/constants/navigation';
import { Gap } from '@utils/layout';
import { storage, StorageKey } from '@utils/storage';
import { BoxShadow } from '@utils/styles/box-shadow';
import { spacing } from '@utils/tailwind';
import { MINUTE, SECOND, timeout } from '@utils/time';
import clsx from 'clsx';
import { useEffect } from 'react';
import { Linking, Pressable, ScrollView, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useGlobal } from '../hooks/useGlobal';
import { useOverlay } from '../hooks/useOverlay';
import { useWindow } from '../hooks/useWindow';

export function MerchantAdminHeader(): JSX.Element {
  const { showModal, hideModal, showSuccess } = useOverlay();
  const { session, updateSessionMerchantAdmin, statuses, setStatuses, logout } = useGlobal();
  const { merchantAdmin, user } = session || {};
  const { showMenu, hideMenu, showToast, hideToast } = useOverlay();
  const { isDesktopWeb, isWide, height } = useWindow();
  const { getHeaderBeaconConfig, getHeaderTooltipConfig } = useMerchantStatusConfigs({ merchantAdmin, statuses });
  const { updateMerchantAdminOnboardingStatus } = useUpdateMerchantAdminOnboarding();

  const navigation = useNavigation();
  const insets = useSafeAreaInsets();
  const userFullName = user?.firstName + ' ' + user?.lastName;

  const showAnalysisCompleteToast = (): void => {
    if (statuses?.isBillingEnabled) {
      showToast({
        content: (
          <EcoAlert
            variant="info"
            closeable={false}
            subtitle="Your store analysis is complete! Go to Settings to enable widgets."
            primaryButton={{
              label: 'Settings',
              onPress: () => {
                navigation.navigate('SettingsTab', { screen: 'SettingsGeneral' });
                hideToast();
              },
              style: { marginRight: spacing[9] }
            }}
          />
        ),
        config: { dismissTimeMs: 60 * MINUTE }
      });
    } else {
      showToast({
        content: (
          <EcoAlert
            variant="info"
            closeable={false}
            subtitle="Your store analysis is complete! Add a payment method to begin using EcoCart."
            primaryButton={{
              label: 'Billing',
              onPress: () => {
                navigation.navigate('BillingTab');
                hideToast();
              },
              style: { marginRight: spacing[9] }
            }}
          />
        ),
        config: { dismissTimeMs: 60 * MINUTE }
      });
    }
  };

  useEffect(() => {
    if (!merchantAdmin?.shopName || !!merchantAdmin.variantIds?.[0]) return;

    const checkForVariants = async () => {
      const { variantIds } = await getMerchantAdmin(merchantAdmin?.shopName || '');

      if (variantIds?.[0]) {
        setStatuses({ ...statuses, areVariantsCreated: true });
        showAnalysisCompleteToast();
      } else {
        await timeout(15 * SECOND);
        checkForVariants();
      }
    };

    checkForVariants();
  }, [merchantAdmin]);

  const handleSetActiveMerchant = (shopName: string) => {
    if (shopName === merchantAdmin?.shopName) return;

    getMerchantAdmin(shopName).then(async (merchantAdmin) => {
      updateSessionMerchantAdmin(merchantAdmin);

      const paymentMethods = await getPaymentMethods(merchantAdmin.shopName);

      setStatuses({
        areVariantsCreated: !!merchantAdmin?.variantIds[0] || false,
        isOnboardingComplete: merchantAdmin.isOnboardingComplete,
        isBillingEnabled: hasAtLeastOnePaymentMethod(paymentMethods),
        hasAValidPaymentMethod: hasAtLeastOneValidPaymentMethod(paymentMethods)
      });

      hideMenu();
    });
  };

  const handleLogout = async () => {
    await storage.removeItem(StorageKey.login_redirect);
    logout();
    hideMenu();
  };

  const handleResetOnboarding = () => {
    hideMenu();
    showModal({
      content: (
        <EcoCard size="lg">
          <View style={Gap()}>
            <EcoText fontWeight="semibold" fontSize="lg" textAlign="center">
              Are you sure?
            </EcoText>
            <EcoText textAlign="center">
              This will reset the onboarding status for your account and should be used for QA purposes only.
            </EcoText>
            <View className="flex flex-row mt-2">
              <EcoButton className="flex-1 mr-2" variant="outlined" onPress={hideModal}>
                Cancel
              </EcoButton>
              <EcoButton
                className="flex-1 ml-2"
                colorScheme="danger"
                onPress={async () => {
                  hideModal();
                  try {
                    await updateMerchantAdminOnboardingStatus(false);
                    showSuccess('Updated merchant admin!');
                  } catch (error) {
                    console.error('Cannot update onboarding status', error);
                  }
                }}
              >
                Reset
              </EcoButton>
            </View>
          </View>
        </EcoCard>
      )
    });
  };

  const isActiveStore = (shopName: string): boolean => shopName === merchantAdmin?.shopName;
  const { isFeaturePermissioned } = useFeaturePermission();

  const handleOpenMenu = () => {
    if (!session) return;

    showMenu({
      content: (
        <View className="p-3 rounded-sm bg-white" style={BoxShadow('softdeep')}>
          <View className="p-3 flex-row justify-start">
            <EcoAvatar name={userFullName} className="mr-4" src={DEFAULT_AVATAR} />
            <View style={Gap(3)}>
              <View>
                <EcoText fontWeight="semibold">{userFullName}</EcoText>
                <EcoText fontSize="sm">{merchantAdmin?.companyName}</EcoText>
              </View>
              <EcoButton
                nativeID="profile-menu__view-profile-button"
                size="sm"
                onPress={() => {
                  navigation.navigate('SettingsTab', { screen: 'SettingsUser' });
                  hideMenu();
                }}
              >
                View Profile
              </EcoButton>
            </View>
          </View>
          <View className="rounded-sm bg-gray-50 overflow-hidden">
            <View style={Gap(2)} className="justify-start items-center flex-row p-3 border-b border-gray-100">
              <EcoIcon className="pointer-event-none text-gray-700" name="storefront" />
              <EcoText fontSize="sm" color="disabled-inverted">
                Stores
              </EcoText>
            </View>
            <ScrollView style={{ maxHeight: height - 375 }}>
              {Object.keys(user?.merchants || {}).map((shopName) => (
                <Pressable key={shopName} onPress={() => handleSetActiveMerchant(shopName)} nativeID="profile-menu__shop-name">
                  <View style={Gap(2)} className="flex-row justify-between items-center p-3 hover:bg-gray-100">
                    <View style={Gap(2)} className="flex-row justify-start items-center">
                      <EcoAvatar
                        size="md"
                        name={isActiveStore(shopName) ? merchantAdmin?.companyName : shopName.replace('.myshopify.com', '')}
                        className={clsx('pointer-event-none', isActiveStore(shopName) && 'border-[1px] border-primary-300')}
                      />
                      <View>
                        <EcoText fontSize="sm" color="disabled-inverted">
                          {isActiveStore(shopName) ? merchantAdmin?.companyName : shopName.replace('.myshopify.com', '')}
                        </EcoText>
                        {isActiveStore(shopName) ? (
                          <EcoText fontSize="2xs" className="text-primary">
                            Active
                          </EcoText>
                        ) : null}
                      </View>
                    </View>
                    {isActiveStore(shopName) ? <EcoIcon className="text-gray-700" name="check" /> : null}
                  </View>
                </Pressable>
              ))}
            </ScrollView>
          </View>

          <Pressable
            nativeID="profile-menu__settings-button"
            onPress={() => {
              navigation.navigate('SettingsTab', { screen: 'SettingsGeneral' });
              hideMenu();
            }}
          >
            <View style={Gap(2)} className="flex-row justify-start items-center p-3 rounded-xs hover:bg-gray-100">
              <EcoIcon className="pointer-event-none text-gray-700" name="settings" />
              <EcoText fontSize="sm" color="disabled-inverted">
                Settings
              </EcoText>
            </View>
          </Pressable>

          {/* for QA Product Fruits Onboarding process. This is to reset the isOnboardingComplete to false so that 
          we can test the PF onboarding process again. Remember to reset the checklist / tours in Product Fruits Admin tools as well */}
          {isFeaturePermissioned && (
            <Pressable onPress={handleResetOnboarding} nativeID="profile-menu__logout-button">
              <View style={Gap(2)} className="flex-row justify-start items-center p-3 bg-danger-600 rounded-xs hover:bg-danger-700">
                <EcoIcon className="pointer-event-none text-white" name="warning" />
                <EcoText fontSize="sm" color="enabled-inverted">
                  Reset Onboarding
                </EcoText>
              </View>
            </Pressable>
          )}

          <Pressable onPress={() => Linking.openURL('https://support.ecocart.io/knowledge')}>
            <View style={Gap(2)} className="flex-row justify-start items-center p-3 rounded-xs hover:bg-gray-100">
              <EcoIcon className="pointer-event-none text-gray-700" name="help" />
              <EcoText fontSize="sm" color="disabled-inverted">
                Help Center
              </EcoText>
            </View>
          </Pressable>

          <Pressable onPress={handleLogout} nativeID="profile-menu__logout-button">
            <View style={Gap(2)} className="flex-row justify-start items-center p-3 rounded-xs hover:bg-gray-100">
              <EcoIcon className="pointer-event-none text-gray-700" name="logout" />
              <EcoText fontSize="sm" color="disabled-inverted">
                Log out
              </EcoText>
            </View>
          </Pressable>
        </View>
      ),
      config: {
        position: {
          top: insets.top + TOP_NAV_HEIGHT,
          right: 16
        }
      }
    });
  };

  const getLogoUrl = (): string => {
    if (merchantAdmin?.logoUrl && merchantAdmin?.platform === 'api') return merchantAdmin.logoUrl;
    if (merchantAdmin?.pkgProtectionEnabled) return PUBLIC_IMG_URL + '/shipinsure-icon.png';
    return PUBLIC_IMG_URL + '/ecocart-icon.svg';
  };

  return (
    <View className="z-[10001] w-full" style={{ height: TOP_NAV_HEIGHT }}>
      {merchantAdmin ? (
        <View className="flex-row justify-between items-center w-full h-full">
          {isDesktopWeb ? (
            <EcoLink
              href={merchantAdmin.pkgProtectionEnabled ? '/claims/homepage' : '/home'}
              style={{ width: SIDE_NAV_WIDTH, height: TOP_NAV_HEIGHT }}
              className="flex-row justify-center"
            >
              <EcoImage width={50} height={TOP_NAV_HEIGHT} resizeMode="contain" src={getLogoUrl()} />
            </EcoLink>
          ) : null}
          <View className="flex-row justify-between items-center px-4 grow h-full border-b border-gray-100">
            <View>
              {/* {statuses?.isOnboardingComplete === false ? ( */}
              {!statuses?.areVariantsCreated ? (
                <EcoTooltip {...getHeaderTooltipConfig('store_processing')}>
                  <EcoStatusBeacon {...getHeaderBeaconConfig('store_processing')} />
                </EcoTooltip>
              ) : null}
            </View>
            <View style={Gap(2)} className="flex-row items-center">
              <EcoTooltip {...getHeaderTooltipConfig('ecocart_active')}>
                <EcoStatusBeacon {...getHeaderBeaconConfig('ecocart_active')} />
              </EcoTooltip>
              <EcoTooltip {...getHeaderTooltipConfig('widgets_visible')}>
                <EcoStatusBeacon {...getHeaderBeaconConfig('widgets_visible')} />
              </EcoTooltip>
              <Pressable onPress={handleOpenMenu} nativeID="profile-menu">
                <View className="flex-row items-center" style={Gap(2)}>
                  <EcoAvatar name={userFullName} />
                  {isWide ? (
                    <View>
                      <EcoText fontWeight="semibold">{userFullName}</EcoText>
                      <EcoText fontSize="sm">{merchantAdmin.companyName}</EcoText>
                    </View>
                  ) : null}
                </View>
              </Pressable>
            </View>
          </View>
        </View>
      ) : null}
    </View>
  );
}
